<template>
  <div class="home">
    <el-row
      :gutter="0"
      type="flex"
      justify="center"
      align="middle"
      style="height: 7%; backgroundColor: #154685"
    >
      <el-col :span="8" class="title"
        ><div>养护作业安全卫士管理系统</div></el-col
      >
      <el-col :span="8" class="timetext">
        <div v-if="userRoleId === '1' || userRoleId === '2'">
          守护总时：<span class="time_length">{{ total_time }}</span>
        </div>
      </el-col>
      <el-col :span="8" class="userselect">
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            用户菜单<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="a">修改个人信息</el-dropdown-item>
            <el-dropdown-item command="b">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
    <el-row style="height: 93%; backgroundcolor: #ffffff">
      <el-col :span="3" style="min-height: 100%; backgroundColor: #01295c">
        <el-menu
          unique-opened
          router
          :default-active="this.$route.path"
          style="min-height: 100%; fontsize: 1rem"
          background-color="#01295C"
          text-color="#FFFFFF"
          active-text-color="#333333"
          mode="vertical"
        >
          <el-menu-item index="/home/admap" key="1">
            <i class="el-icon-location"></i>
            <span slot="title">地图主页</span>
          </el-menu-item>
          <el-menu-item index="/home/aldata" key="2">
            <i class="el-icon-notebook-2"></i>
            <span slot="title">数据统计</span>
          </el-menu-item>
          <el-menu-item index="/home/adalarmHistory" key="3">
            <i class="el-icon-document"></i>
            <span slot="title">历史报警</span>
          </el-menu-item>
          <el-menu-item index="/home/adequipmentLog" key="4">
            <i class="el-icon-document-remove"></i>
            <span slot="title">设备日志</span>
          </el-menu-item>
          <el-menu-item
            v-if="userRoleId === '1' || userRoleId === '2'"
            index="/home/alcompany"
            key="5"
          >
            <i class="el-icon-user"></i>
            <span slot="title">公司管理</span>
          </el-menu-item>
          <el-menu-item
            v-if="userRoleId === '1' || userRoleId === '2'"
            index="/home/alequipment"
            key="6"
          >
            <i class="el-icon-setting"></i>
            <span slot="title">设备管理</span>
          </el-menu-item>

          <el-menu-item
            v-if="userRoleId === '1'"
            index="/home/alsystemLog"
            key="7"
          >
            <i class="el-icon-notebook-1"></i>
            <span slot="title">系统日志</span>
          </el-menu-item>
          <el-menu-item index="/home/myeqm" key="8">
            <i class="el-icon-monitor"></i>
            <span slot="title">我的设备</span>
          </el-menu-item>
          <el-menu-item v-if="userRoleId === '0'" index="/home/payment" key="9">
            <i class="el-icon-mobile-phone"></i>
            <span slot="title">流量充值</span>
          </el-menu-item>
          <el-menu-item
            v-if="userRoleId === '1' || userRoleId === '2'"
            index="/home/orderform"
            key="10"
          >
           <i class="el-icon-mobile-phone"></i>
            <span slot="title">充值订单</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col
        :span="21"
        style="height: 100%; overflow: auto; backgroundcolor: #67c23a"
      >
        <keep-alive>
          <router-view :key="key"></router-view>
        </keep-alive>
      </el-col>
    </el-row>

    <el-dialog
      title="修改用户信息"
      :visible.sync="dialogFormVisible"
      width="30%"
    >
      <el-form
        ref="edituser"
        size="medium"
        class="center"
        style="top: 55%"
        :model="edituser"
        :rules="rules"
      >
        <el-form-item label="账号" :label-width="formLabelWidth" prop="account">
          <el-input
            v-model="edituser.account"
            autocomplete="off"
            placeholder="请输入账号名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="原密码"
          :label-width="formLabelWidth"
          prop="editpwd3"
        >
          <el-input
            v-model="edituser.editpwd3"
            autocomplete="off"
            placeholder="请输入原密码"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="新密码"
          :label-width="formLabelWidth"
          prop="editpwd1"
        >
          <el-input
            v-model="edituser.editpwd1"
            autocomplete="off"
            placeholder="请输入新密码"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="确认密码"
          :label-width="formLabelWidth"
          prop="editpwd2"
        >
          <el-input
            v-model="edituser.editpwd2"
            autocomplete="off"
            placeholder="请确认新密码"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人" :label-width="formLabelWidth" prop="name">
          <el-input
            v-model="edituser.name"
            autocomplete="off"
            placeholder="联系人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="联系电话"
          :label-width="formLabelWidth"
          prop="phone"
        >
          <el-input
            v-model="edituser.phone"
            autocomplete="off"
            placeholder="联系人电话"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="公司地址"
          :label-width="formLabelWidth"
          prop="address"
        >
          <el-input
            v-model="edituser.address"
            autocomplete="off"
            placeholder="公司地址"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetedituser()">重置</el-button>
        <el-button type="primary" @click="submitedituser()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import { user } from "../api/indexMapApi";
import { edituser } from "../api/homeapi";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      console.log(value);
      console.log(this.edituser.editpwd1);
      if (value !== this.edituser.editpwd1) {
        callback(new Error("两次输入密码不一致！"));
      } else {
        callback();
      }
    };
    return {
      total_time: "",
      user: " ",
      dialogFormVisible: false,
      edituser: {
        account: null,
        editpwd1: null,
        editpwd2: null,
        editpwd2: null,
        name: null,
        phone: null,
        address: null,
      },
      formLabelWidth: "7.5rem",
      rules: {
        account: [
          {
            required: true,
            message: "账号不能为空",
            trigger: "blur",
          },
          {
            min: 2,
            max: 20,
            message: "长度在6到20之间",
            trigger: "change",
          },
        ],
        editpwd1: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 5, max: 25, message: "长度在 5 到 25个字符" },
          {
            pattern: /^(\w){5,25}$/,
            message: "只能输入5-25个字母、数字、下划线",
          },
          //{ pattern:/^[a-zA-Z]w{1,4}$/, message: '以字母开头，长度在2-5之间， 只能包含字符、数字和下划线'}],
        ],
        editpwd2: [
          {
            required: true,
            message: "请确认密码",
            trigger: "blur",
          },
          {
            validator: validatePass,
            trigger: "blur",
          },
        ],
        editpwd3: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 5, max: 25, message: "长度在 5 到 25个字符" },
          {
            pattern: /^(\w){5,25}$/,
            message: "只能输入5-25个字母、数字、下划线",
          },
          //{ pattern:/^[a-zA-Z]w{1,4}$/, message: '以字母开头，长度在2-5之间， 只能包含字符、数字和下划线'}],
        ],
        name: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 2, max: 7, message: "长度在 2 到 7 个字符" },
          { pattern: /^[\u4E00-\u9FA5]+$/, message: "用户名只能为中文" },
        ],

        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            validator: function (rule, value, callback) {
              if (/^1[34578]\d{9}$/.test(value) == false) {
                callback(new Error("请输入正确的手机号"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        //pattern: /^1[34578]\d{9}$/, message: '目前只支持中国大陆的手机号码' }
        address: [
          {
            required: true,
            message: "地址不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {},
  methods: {
    // selectMenu(index) {
    //   localStorage.setItem("current_index", index);
    // },
    loginState() {
      this.user = this.$cookies.get("user");
      this.userRoleId = this.$cookies.get("userRoleId");
    },
    /**下拉菜单选项 */
    handleCommand(command) {
      console.log(command);
      console.log("这是菜单");
      if (command == "a") {
        this.dialogFormVisible = true;
      }
      if (command == "b") {
        this.loginout();
      }
    },
    /**退出登录 */
    loginout() {
      this.$cookies.remove("user");
      this.$cookies.remove("token");
      this.$cookies.remove("userRoleId");
     
      this.$cookies.remove("role");
      this.$router.push("/");
    },
    resetedituser() {
      this.$refs.edituser.resetFields();
    },
    /**提交修改个人信息表单 */
    submitedituser() {
      this.$refs.edituser.validate((valid) => {
        if (valid) {
          var param = new URLSearchParams();
          param.append("password", this.edituser.editpwd3);
          param.append("password1", this.edituser.editpwd1);
          param.append("password2", this.edituser.editpwd2);
          console.log(this.edituser.editpwd3);
          console.log(this.edituser.editpwd1);
          console.log(this.edituser.editpwd2);
          console.log("这是发送的密码");
          param.append("contract", this.edituser.name);
          param.append("phone", this.edituser.phone);
          param.append("address", this.edituser.address);
          edituser(param)
            .then((res) => {
              if (res.status === 200) {
                console.log(res);
                console.log("这是修改个人信息");
                this.$message.success("用户信息修改成功");
                this.$refs.edituser.resetFields();
                this.dialogFormVisible = false;
              } else {
                this.$message.error("账号修改失败");
              }
            })
            .catch((e) => {
              // this.$message.error(e)
            });
        }
      });
    },
    /**时长转换 */
    changetime(str) {
      if (str) {
        return str < 60
          ? str.toString() + "分钟"
          : str % 60 === 0
          ? (str / 60).toString() + "小时"
          : Math.floor(str / 60).toString() +
            "小时" +
            (str % 60).toString() +
            "分钟";
      } else {
        return "暂无数据";
      }
    },
    getUsers() {
      user()
        .then((res) => {
          if (res.status == 200) {
            console.log(res);
            this.total_time = this.changetime(res.data.total_time);
            this.edituser.account = res.data.name;
            // //  this.edituser.editpwd1;
            //    console.log(res.data.contract)
            //    console.log('联系人')
            //    console.log(res.data.phone)
            //    console.log('电话')
            this.edituser.name = res.data.contract;
            this.edituser.phone = res.data.phone;
            this.edituser.address = res.data.address;
          } else {
          }
          console.log(res);
          console.log("这是用户信息");
        })
        .catch((e) => {
          this.$message.error(e.message);
        });
    },
  },
  computed: {
    key() {
      return this.$route.path + Math.random();
    },
  },
  created() {
    this.loginState();
  },
  mounted() {
    this.getUsers();
  },
};
</script>
<style scoped>
/* @import url("../util/common.less"); */
.home {
  width: 100%;
  height: 100%;
}
.time_length {
  color: aquamarine;
}
.title {
  text-align: start;
  padding: 0 0 0 68px;
  font-size: 24px;
  color: #ffffff;
  font-family: "SourceHanSansSC-" !important;
}
.timetext {
  text-align: center;
  color: #ffffff;
  font-size: 24px;
}
.userselect {
  text-align: end;
  padding: 0 30px 0 0;
}
.el-menu-item {
  text-align: center;
}
.el-menu-item.is-active {
  background-color: #edeff5 !important;
}
.el-dropdown-link {
  cursor: pointer;
  color: #ffffff;
  font-size: 20px;
}
.el-icon-arrow-down {
  font-size: 15px;
}

/* 菜单栏右侧留白 */
.el-menu {
  border-right: 0 !important;
  font-size: 20px !important;
}
@media only screen and (max-width: 1921px) {
  .home {
    width: 100%;
    height: 100%;
  }
  .time_length {
    color: aquamarine;
  }
  .title {
    text-align: start;
    padding: 0 0 0 4.25rem;
    font-size: 1.5rem;
    color: #ffffff;
    font-family: "SourceHanSansSC-" !important;
  }
  .timetext {
    text-align: center;
    color: #ffffff;
    font-size: 1.5rem;
  }
  .userselect {
    text-align: end;
    padding: 0 1.875rem 0 0;
  }
  .el-menu-item {
    text-align: center;
  }
  .el-menu-item.is-active {
    background-color: #edeff5 !important;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #ffffff;
    font-size: 1.25rem;
  }
  .el-icon-arrow-down {
    font-size: 0.9375rem;
  }

  /* 菜单栏右侧留白 */
  .el-menu {
    border-right: 0 !important;
    font-size: 1.25rem !important;
  }
}
@media only screen and (max-width: 1080px) {
  .home {
    width: 100%;
    height: 100%;
  }
  .time_length {
    color: aquamarine;
  }
  .title {
    text-align: start;
    padding: 0 0 0 1.625rem;
    font-size: 0.9375rem;
    color: #ffffff;
    font-family: "SourceHanSansSC-" !important;
  }
  .timetext {
    text-align: center;
    color: #ffffff;
    font-size: 0.9375rem;
  }
  .userselect {
    text-align: end;
    padding: 0 0.625rem 0 0;
  }
  .el-menu-item {
    text-align: center;
  }
  .el-menu-item.is-active {
    background-color: #edeff5 !important;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #ffffff;
    font-size: 0.9375rem;
  }
  .el-icon-arrow-down {
    font-size: 0.875rem;
  }

  /* 菜单栏右侧留白 */
  .el-menu {
    border-right: 0 !important;
    font-size: 0.9rem !important;
  }
}
</style>