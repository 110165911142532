import service from "../util/link"
import url from "../util/apiurl"


/*地图主页请求 */
export function user() {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.user,
            method: "get",

        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function companymarker(obj) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.user,
            method: "get",
            params: {
                company_id: obj
            }
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function info(obj) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.user,
            method: "get",
            params: {
                codeid: obj
            }
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function startplay(obj) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.liveplay,
            method: "post",
            data: obj
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function closeplay(obj) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.closevide,
            method: "post",
            data: obj
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function looprequest(obj) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.looprequest,
            method: "post",
            data: obj
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function definition(obj) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.looprequest,
            method: "post",
            data: obj
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}